<template>
  <div class="container">
    <div>
      <img src="https://ol-image-1304545655.cos.ap-shanghai.myqcloud.com/PC-2023/09/30/1698645166394290" class="img"/>
    </div>
    <div class="light">
      <img src="@/assets/images/activity/business-page/img1.png" class="img" style="width: 1200px;margin: 21px 0 66px;"/>
    </div>
    <div>
      <img src="@/assets/images/activity/business-page/img2.png" class="img"/>
    </div>
    <div class="light">
      <img src="@/assets/images/activity/business-page/img3.png" class="img" style="width: 1200px;margin: 79px 0 60px;"/>
    </div>
    <div class="light">
      <img src="@/assets/images/activity/business-page/img4.png" class="img" style="width: 1200px;margin-bottom: 15px;"/>
    </div>
    <div class="dark">
      <img src="@/assets/images/activity/business-page/img5.png" class="img" style="width: 1200px;margin: 60px 0 40px;"/>
    </div>
    <div>
      <img src="@/assets/images/activity/business-page/img6.png" class="img"/>
    </div>
    <div class="light">
      <img src="@/assets/images/activity/business-page/img7.png" class="img" style="width: 1200px;margin: 77px 0 70px;"/>
    </div>
    <div>
      <img src="@/assets/images/activity/business-page/img8.png" class="img"/>
    </div>
    <div>
      <img src="@/assets/images/activity/business-page/img9.png" class="img"/>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.container{
  width: 100%;
  .img{
    width: 100%;
  }
  .light{
    width: 100%;
    background: #ffffff;
    display: flex;
    justify-content: center;
  }
  .dark{
    width: 100%;
    background: #F6F7F9;
    display: flex;
    justify-content: center;
  }
}
</style>