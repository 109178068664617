<template>
    <div class="login-container">
    </div>
</template>

<script>
  import { validatePhone } from '@/utils/common/validate'
  export default {
    name: "tempLogin",
    data(){
        return{
            loading: true,
            show: true, // 判断是否点击了发送按钮
            bindPhone: false,
            loginForm:{
                phoneNumber: '',
                captcha: ''
            },
            repeatFlag: false
        }
    },
    methods:{
        validPhone(phone) { // 判断手机号是否合法
        if (!validatePhone(phone)) {
          return false;
        } else if (phone === "") {
          return false;
        } else {
          return true;
        }
      },
    }
  }
</script>

<style scoped lang="scss">
  @import '../../assets/css/color.scss';
  .fix-top {
    position: fixed;
    top: 0;
  }
  .login-container {
    height: calc(100vh - 70px);
    width: 100%;
    background: url("../../assets/images/login-bg.png") no-repeat center center;
    background-size: cover;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
  }
  .code-btn-disable {
    width: 30%;
    height: 44px;
    margin-left: 10px;
    border: none !important;
    font-size: 22px;
    font-weight: 400;
	background: #ffffff !important;
    ::v-deep span{
      color: #F57B15 !important;
    }
  }
  .scan-login{
    margin-top: 60px;
    background: #F1F1F1;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
    height: 630px;
    width: 320px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 10px;
    border-right: 1px solid gray;
    flex-direction: column;
    position: relative;
    .top-pic{
      position: absolute;
      top: 60px;
      width: 136px;
      height: 50px;
    }
    .cover-text{
      position: absolute;
      top: 110px;
      width: 136px;
      height: 40px;
      background: rgb(241, 241, 241);
    }
    .scan-text{
      font-size: 20px;
      font-family: PingFang SC;
      font-weight: 400;
      color: #0F1C34;
    }
  }
  .border-right-top{
    border-top-right-radius: 8px;
  }
  .login-panel {
    margin-top: 60px;
    width: 552px;
    min-height: 660px;
    overflow: hidden;
    position: relative;
    z-index: 9999;
    border-bottom-right-radius: 8px;
    .cancel-button{
      position: absolute;
      right: 28px;
      top: 28px;
      cursor: pointer;
      img{
        width: 28px;
        height: 28px;
      }
    }
  }
  .login-title {
    background: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 70px;
    height: 60px;
  }
  .code-button {
    display: inline-block;
    width: 30%;
    height: 44px;
    margin-left: 10px;
    line-height: .8;
    font-size: 22px;
    font-family: Source Han Sans SC;
    font-weight: 400;
    border: none !important;
    background: #ffffff;
    ::v-deep span{
      color: #F57B15 !important;
    }
  }
  .code-button :hover,
  .code-button :active {
    display: inline-block;
    ::v-deep span{
      color: #F57B15 !important;
    }
  }
  .login-form {
    background: white;
    min-height: 500px;
    text-align: center;
  }
  .bind-form {
    min-height: 450px;
  }
  .input-container {
    width: 100%;
    padding: 40px 50px 10px 50px;
    box-sizing: border-box;
    display: flex;
    justify-content: flex-start;
    position: relative;
    .empty-phone{
      position: absolute;
      right: 50px;
      bottom: -20px;
      font-size: 16px;
      font-family: PingFang SC;
      font-weight: 400;
      color: #E3373F;
    }
    .empty-code{
      position: absolute;
      right: 50px;
      bottom: -20px;
      font-size: 16px;
      font-family: PingFang SC;
      font-weight: 400;
      color: #E3373F;
    }
    .code{
      position: absolute;
      right: 51px;
      top: 50%;
      margin-top: -7px;
    }
    .code::before{
      position: absolute;
      content: '';
      width: 2px;
      height: 14px;
      background: #DFE0E5;
      border-radius: 1px;
      left: -5px;
      top: 50%;
      margin-top:-7px;
    }
    img{
      position: absolute;
      width: 30px;
      height: 34px;
      left: 70px;
      top: 50%;
      margin-top: -2px;
    }
    ::v-deep .is-disabled{
      background: #f09a53;
      // border-radius: 18px;
    }
    ::v-deep input{
      height: 44px;
      font-size: 22px;
      border-radius: 0 !important;
    }
    .input-box{
      height: 44px;
      border: 1px solid #DFE0E5;
    }
    .input-box-code{
      width: 100%;
      height: 44px;
      border: 1px solid #DFE0E5;
    }
  }
  ::v-deep .el-input__inner {
    border: 0px solid #eee !important;
  }
  .tip-container {
    margin-top: 30px;
    padding: 0px 30px 0 30px;
    color: #999999;
    font-size: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    i{
      color: #F57B15;
      font-size: 18px;
      padding-right: 10px;
    }
  }
  .clear-icon {
    cursor: pointer;
    font-size: 22px;
    margin-top: 3px;
  }
  .login-button-container {
    margin-top: 20px;
    display: flex;
    justify-content: center;
  }
  .login-button {
    width: 82%;
    border-radius: 4px;
    cursor: pointer;
    height: 66px;
    background: #F57B15;
    color: white;
    font-size: 28px;
    font-family: Source Han Sans SC;
    font-weight: 400;
    color: #FFFFFF;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .login-button:hover {
    background: $--color-theme;
  }
  .protocols {
    box-sizing: border-box;
    width: 100%;
    margin-top: 20px;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    line-height: 24px;
    padding: 10px 50px 0 50px;
    font-size: 14px;
    font-family: Source Han Sans SC;
    font-weight: 400;
    color: #B0B5C0;
    float:left;
    text-align: justify;
    word-break: break-all;
    a:hover{
      color: #faae6f;
    }
  }
  .protocol-name {
    color: #B0B5C0;
    word-break: break-all;
  }
  .other-login{
    margin-top: 90px;
    cursor: pointer;
    display: flex;
    width: 100%;
    justify-content: space-around;
    img{
      width: 36px;
      height: 36px;
      padding-bottom: 10px;
    }
    .login-type{
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    ::v-deep .el-dialog__header{
      background: #E9E9E9;
      min-width: 300px;
      position: relative;
    }
    ::v-deep .el-dialog__body{
      background: #E9E9E9;
      min-width: 300px;
    }
  }
  .bind-info{
    ::v-deep .el-dialog__header{
      background: #ffffff;
      min-width: 300px;
      position: relative;
    }
    ::v-deep .el-dialog__body{
      background: #ffffff;
      min-width: 300px;
    }
  }
  .bind-word{
    font-size: 20px;
    font-family: Source Han Sans SC;
    font-weight: 400;
    color: #333333;
  }
  .bind-btns{
    width: calc(100% + 40px);
    margin-left: -20px;
    margin-top: 20px;
    margin-bottom: -30px;
    border-top: 2px solid #c7c7c7;
    display: flex;
    align-items: center;
    .bind-btn{
      width: 50%;
      font-size: 18px;
      font-family: Source Han Sans SC;
      font-weight: 400;
      color: #666666;
      padding: 20px 0;
      cursor: pointer;
    }
    .confirm{
      color: #EC8541;
      border-left: 2px solid #c7c7c7;
    }
  }
</style>