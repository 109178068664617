<template>
  <div class="root">
    <div class="content">
      <h2 class="align-center">科企岛法律声明</h2>
      <div class="content-text">
        <h4>版权</h4>
        <p class="suojin">
          本平台包含之所有内容：文本、图形、LOGO、创意、及软件等之所有权归属科企岛及本平台的内容/信息提供者，受中国及国际版权法的保护。对本平台上所有内容之复制（意指收集、组合和重新组合），本平台享有排他权并受中国及国际版权法的保护。本平台使用的所有软件的所有权归属于科企岛或它的软件供应商并受中国及国际版权法的保护。在本平台上的内容和软件可以用作信息获取网上交易及网上拍卖的信息资源，对本平台该等内容的任何其他使用，包括再造、修改、发布、转发、再版、演示或播出被严格禁止。</p>
        <h4>平台使用</h4>
        <p class="suojin">
          科企岛及其任何组成部分不得被再造、复制、抄袭、交易，或为任何未经科企岛允许的商业目的所使用。如果科企岛确定用户行为违法或有损其平台和企业的利益，则科企岛和其关联企业将保留，包括但不限于拒绝提供服务、冻结会员专有帐户、和/或取消交易的权利。</p>
        <h4>评论</h4>
        <p class="suojin">
          科企岛允许用户在平台上发表个性化意见。如果您在本平台上发表评论，您即授权科企岛在世界范围内的任何媒体上享有非排他，永久，不可更改及完全的许可权利去使用、重新加工、修改、采用、出版、翻译、派生、分发及播出该评论。你授权科企岛可以引用你发表评论时的署名，并如果有，可以附你发表的评论。</p>
        <h4>链接</h4>
        <p class="suojin">科企岛上的链接服务可以直接进入其他站点，这些链接的站点不受科企岛的控制，科企岛对任何与本站链接平台的内容不负责任。</p>
        <h4>版权投诉</h4>
        <p class="suojin">科企岛尊重他人之知识产权。如果你确认你的作品以某种方式被抄袭，该行为触犯了中国及国际版权法，请向科企岛做版权投诉。</p>
        <h4>免责</h4>
        <p class="suojin">
          科企岛对本平台上所有内容包括但不限于平台运营或本平台上的信息、内容、材料或产品，不提供明示或暗示的担保。由所适用法律许可，科企岛否认所有明示或暗示的担保，包括但不限于为特定目的做的商务暗示担保。科企岛对任何损失或任何由于使用本平台而引起的损失，包括但不限于直接的，间接的，偶然的、惩罚性的和引发的损失，不承担责任。</p>
        <p class="suojin">1、会员注册名、密码和保密</p>
        <p class="suojin">
          在您按照注册页面提示填写信息、阅读并同意本协议并完成全部注册程序后或以其他科企岛允许的方式实际使用科企岛平台服务时，您即成为科企岛会员（亦称会员），科企岛根据会员注册名和密码确认您的身份。您须自行负责对您的会员注册名和密码保密，且须对您在会员注册名和密码下发生的所有活动（包括但不限于发布信息资料、网上点击同意或提交各类规则协议、网上续签协议或购买服务等）承担责任。您同意：(a)
          如发现任何人未经授权使用您的会员注册名或密码，或发生违反保密规定的任何其他情况，您会立即通知科企岛；及 (b)
          确保您在每个上网时段结束时，以正确步骤离开平台。科企岛不能也不会对因您未能遵守本款规定而发生的任何损失或损毁负责。您理解科企岛对您的请求采取行动需要合理时间，科企岛对在采取行动前已经产生的后果（包括但不限于您的任何损失）不承担任何责任。</p>
        <p class="suojin">2、处理交易争议</p>
        <p class="suojin">科企岛不涉及用户间因交易而产生的法律关系及法律纠纷，不会且不能牵涉进交易各方的交易当中。倘若您与一名或一名以上用户，或与您通过本公司平台获取其服务的第三者服务供应商发生争议，您免除科企岛
          (及本公司代理人和雇员) 在因该等争议而引起的，或在任何方面与该等争议有关的不同种类和性质的任何(实际和后果性的) 权利主张、要求和损害赔偿等方面的责任。</p>
        <p class="suojin">3、科企岛平台仅作为交易地点</p>
        <p class="suojin">
          本公司平台仅作为用户物色交易对象，就产品和服务的交易进行协商，以及获取各类与产品或技术相关的服务的地点。但是，本公司不能控制交易所涉及的产品的质量、安全或合法性，交易信息的真实性或准确性，以及交易方履行其在协议项下的各项义务的能力。本公司不能也不会控制交易各方能否履行协议义务。此外，您应注意到，与外国国民、未成年人或以欺诈手段行事的人进行交易的风险是客观存在的。</p>
        <h4>操作规则</h4>
        <p class="suojin">
          下面的条款和前面所述的条款均适用于本平台上的讨论区，聊天页，动态和任何在平台上发生有相互影响作用区域的行为。网上用户使用、进入或参与上述区域或平台上的任何其他具有相互影响作用的区域，则表示同意所有这些条款。您参与的在线沟通是实时发生的，没有经过任何来自本平台的编辑、压缩、或任何其他形式的控制。本平台不能也没有甄别所有平台用户提供的内容。但本平台保留对本平台上内容的监控权，并对本平台认为具有危害性，引起争议性的或违反本操作规则的其他内容进行删除的权利。为了提供满足本平台用户需求的高价值信息的服务，并避免本平台用户遭受因对他人权利的不实、恶意攻击或其他有害陈述而引起的损失，本平台有必要建立下列操作规则以避免本平台之被滥用：</p>
        <p class="suojin">当您使用本平台服务时，您不可以：</p>
        <p class="suojin">1）
          粘贴或传播任何非法的，具威胁性的，诽谤性的，贬损的，报复的、亵渎的或任何其他法律禁止的信息，包括但不限于传播任何煽动性鼓励犯罪的，或违反公民义务或任何其他违反地方法规、国家法律、法规或国际法律、惯例或公约的内容。</p>
        <p class="suojin">2） 粘贴或传播任何散布任何他人的私人事件，粘贴或传播带有病毒，或任何带有贬损或损害性特征的内容；</p>
        <p class="suojin">3） 粘贴或传播任何可能侵害其他人财产权利的数据、图形或程序，包括以非法形式使用未经注册的版权文本、图形或程序，商业秘密及其他保密信息、 商标、服务标识等；</p>
        <p class="suojin">4） 以任何形式干扰本平台的其他用户。</p>
        <h4>适用法律</h4>
        <p class="suojin">科企岛网（www.keqidao.com）由科企岛（上海）网络技术有限公司运营和控制，适用中华人民共和国法律。我们保留随时更改我们的平台和上述免责及条款的权利。</p>
      </div>

    </div>
  </div>
</template>

<script>
export default {
  name: "contentstatement",
  created() {
    this.$store.commit('message/SET_ACTIVENAV', '-')
    this.$store.commit('message/SET_BG', false)
  }
}
</script>

<style scoped>
.root {
  -moz-user-select: none;
  /* Firefox */
  -webkit-user-select: none;
  /* WebKit */
  -ms-user-select: none;
  /* IE */
  -khtml-user-select: none;
  /* KHTML */
  -o-user-select: none;
  /* Opera */
  user-select: none;
  /* CSS3属性 */

  display: flex;
  align-items: center;
  justify-content: center;
  padding: 50px 50px;
}

.content {
  width: 1000px;
  line-height: 2;
}

.align-center {
  text-align: center;
}

.content-text {
  font-size: 10.5000pt;
}

.bold {
  font-weight: bold;
}

.suojin {
  text-indent: 2em;
}
</style>
