<template>
  <div class="container">
    <img
      @load="imgLoad"
      src="https://ol-image-1304545655.cos.ap-shanghai.myqcloud.com//2022/09/12/1665540011680639"
      alt=""
      class="title"
    />
    <div class="form">
      <p>请填写创建产学研信息</p>
      <el-form :model="form" ref="form" :rules="rules">
        <el-form-item label="实验室名称" class="required">
          <el-input
            v-model="form.labName"
            placeholder="请输入实验室名称"
            autocomplete="off"
            :maxlength="40"
          />
        </el-form-item>
        <el-form-item label="所属单位" class="required">
          <el-input
            v-model="form.belong"
            placeholder="请输入所属单位"
            autocomplete="off"
            :maxlength="40"
          />
        </el-form-item>
        <el-form-item label="姓名" class="required">
          <el-input
            v-model="form.memberName"
            placeholder="请输入姓名"
            autocomplete="off"
            :maxlength="40"
          />
        </el-form-item>
        <el-form-item label="研究方向" class="required">
          <el-input
            v-model="form.direction"
            placeholder="请输入研究方向，以'，'隔开，最多输入五项"
            autocomplete="off"
            :maxlength="50"
          />
        </el-form-item>
        <!-- 学历 -->
        <el-form-item label="学历" class="required">
          <el-select
            style="width: 100%"
            v-model="form.education"
            clearable
            :placeholder="'请选择学历'"
          >
            <el-option
              v-for="item in educations"
              :key="item.id"
              :label="item.val"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>

        <!-- 职称 -->
        <el-form-item label="职称" class="required">
          <el-select
            style="width: 100%"
            v-model="form.jobTitle"
            clearable
            :placeholder="'请选择职称'"
          >
            <el-option
              v-for="item in jobtitlelist"
              :key="item.id"
              :label="item.val"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="联系邮箱" class="required">
          <el-input
            v-model="form.email"
            placeholder="请输入联系邮箱"
            autocomplete="off"
            :maxlength="40"
          />
        </el-form-item>
        <!-- 自我介绍 -->
        <el-form-item label="自我介绍">
          <el-input
            v-model="form.introduction"
            placeholder="请输入自我介绍，不少于10字，不超过400字"
            autocomplete="off"
            :maxlength="400"
          />
        </el-form-item>
        <!-- 地址 -->
        <el-form-item label="实验室地址" class="required">
          <el-cascader
            ref="cascaderAddr"
            :props="props"
            style="width: 100%; background: #f3f3f3 !important"
            :placeholder="'请选择实验室地址'"
            @visible-change="visibleChange" @change="visibleChange" @expand-change="visibleChange"
            v-model="location"
          ></el-cascader>
        </el-form-item>
        <el-form-item label="拍摄上传工作证或校园卡" class="upload required">
          <p class="small-tip">(仅做认证使用，不会外泄)</p>
          <el-upload
            ref="upload1"
            action="#"
            :limit="9"
            :accept="'image/*'"
            list-type="picture-card"
            :on-exceed="handleExceed"
            :http-request="handleUpload"
            :on-change="loadJsonFromFile"
            :before-upload="beforeUpload"
          >
            <template #default>
              <i class="el-icon-plus"></i>
            </template>
            <template #file="{ file }">
              <el-progress
                v-if="file.percentage != 100"
                type="circle"
                :percentage="file.percentage"
              ></el-progress>
              <div
                v-if="file.percentage == 100"
                style="position: relative; width: 100%; height: 100%"
              >
                <img
                  v-if="file.raw.type[0] == 'i'"
                  class="el-upload-list__item-thumbnail"
                  :src="file.url"
                  alt=""
                />
                <div @click="deleteVideo(file)" class="icon-delete">
                  <i
                    class="el-icon-delete"
                    style="fontsize: 16px; color: #fff"
                  ></i>
                </div>
              </div>
            </template>
          </el-upload>
          <p class="tip">仅做为认证信息，暂不对外展示备份</p>
        </el-form-item>
        <el-form-item label="手机号" class="required" v-if="unLogin">
          <el-input
            v-model="form.contactWay"
            placeholder="请输入手机号"
            autocomplete="off"
            @input="handleNumber(0)"
            :maxlength="11"
          />
        </el-form-item>
        <div class="form-code" v-if="unLogin">
          <el-form-item>
            <el-input v-model="form.code" autocomplete="off" :maxlength="6" @input="handleNumber(1)"/>
          </el-form-item>
          <el-button v-if="showCode" type="primary" @click="getPhoneCode"
            >发送验证码</el-button
          >
          <div v-else class="count-down" style="color: #b0b5c0">{{ count }}S</div>
        </div>
        <el-form-item>
          <el-button type="primary" @click="submit" class="button" :disabled="btnDisabled"
            >提交</el-button
          >
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>
<script>
import { getToken } from "@/utils/auth";
import { ElMessage } from "element-plus";
import { getPhoneCode } from "@/api/user"; // 获取用户信息，更新名字头像
// import {environment} from "@/utils/consts";
import { randomFilename } from "@/utils/time";
import { getprovince, getcity, getarea } from "@/api/demand";
import { getSelectFatherSonByType } from "@/api/config";
import { bucketMap,requestVersion} from "@/utils/consts";
import { bindLab } from "@/api/user";
import {
  uploadTypeImage,
  uploadTypeVideo,
  cosByUploadType,
} from "@/api/upload";
import { trackCreate } from "@/utils/tool";
import to from "await-to-js"


export default {
  data() {
    return {
      unLogin: false, // 是否未登录
      rules: {
        memberName: [
          { required: true, message: "请输入姓名", trigger: "blur" },
        ],
        contactWay: [
          { required: true, message: "请输入手机号", trigger: "blur" },
        ],
      },
      imgLoaded: false, //背景加载
      showCustom: false, // 定制弹窗
      educations: [], // 学历
      jobtitlelist: [], // 职称
      location: [], // 地址
      customName: "", // 姓名
      customPhone: "", // 电话
      dialogFormVisible: false,
      form: {},
      count: 60,
      showCode: true, // 是否显示倒计时
      timer: null,
      btnDisabled: false, // 按钮禁用
      licenseImgs: [], // 上传的图片(用于保存)
      uploadimgs: [], // 上传的图片
      props: {
        lazy: true,
        lazyLoad(node, resolve) {
          setTimeout(() => {
            if (node.level == 0) {
              getprovince().then((response) => {
                const cities = [];
                for (let item of response.data) {
                  cities.push({
                    value: item.provinceCode,
                    label: item.provinceName,
                    leaf: false,
                  });
                }
                resolve(cities);
              });
            }
            if (node.level == 1) {
              getcity(node.value).then((response) => {
                const areas = [];
                for (let item of response.data) {
                  areas.push({
                    value: item.cityCode,
                    label: item.cityName,
                    leaf: false,
                  });
                }
                resolve(areas);
              });
            } else if (node.level == 2) {
              getarea(node.value).then((response) => {
                const areas = [];
                for (let item of response.data) {
                  areas.push({
                    value: item.areaCode,
                    label: item.areaName,
                    leaf: true,
                  });
                }
                resolve(areas);
              });
            }
          }, 100);
        },
      },
    };
  },
  async created() {
    this.unLogin = localStorage.getItem('userInfo') ? false : true
    setTimeout(()=>{
      // 防止崩溃
      const $el = document.querySelectorAll('.el-cascader-panel .el-cascader-node[aria-owns]');
      Array.from($el).map((item) => item.removeAttribute('aria-owns'));
    },1000)
    await this.initSelect();
  },
  methods: {
    visibleChange(){
      this.$nextTick(() => {
        const $el = document.querySelectorAll('.el-cascader-panel .el-cascader-node[aria-owns]');
        Array.from($el).map((item) => item.removeAttribute('aria-owns'));
      });
    },
    handleNumber(type){  // 数字处理
      let pattern = new RegExp("^[0-9]|-$")
      switch (type) {
        case 0:
          this.form.contactWay = Array.from(this.form.contactWay).filter(v => pattern.test(v)).join("")
          break;
        default:
          this.form.code = Array.from(this.form.code).filter(v => pattern.test(v)).join("")
          break;
      }
    },
    deleteVideo(file) {
      let arr = this.$refs['upload1'].uploadFiles
      let index = arr.indexOf(file)
      this.$refs['upload1'].uploadFiles.splice(index, 1);
      this.licenseImgs = this.licenseImgs.filter((item,i) => { return i !== index }) // 也要删除腾讯云直传的名称🌟
      let that = this
      this.btnDisabled = true
      setTimeout(function(){
        that.btnDisabled = false
      },1000)
    },
    beforeUpload(file){
      if (file.type.split('/')[0] === 'video') {
        ElMessage.warning('只能上传图片!')
        this.uploadimgs.pop()
      }
    },
    async loadJsonFromFile(file, fileList) {
      console.log(file)
      this.uploadimgs = fileList
      console.log(fileList);
    },
    handleExceed() {
      //文件超出个数限制时的钩子
      ElMessage.warning("最多只能上传9张图片～");
    },
    async handleUpload(val) {
      var result;
      if (val.file.type.split("/")[0] === "image") {
        // 上传的是图片
        result = await this.directUpload(val.file, uploadTypeImage);
        this.btnDisabled = false;
        let temp = result.Location.slice(result.Location.indexOf("/"));
        if (!this.licenseImgs) {
          this.licenseImgs = [temp];
        } else {
          this.licenseImgs.push(...this.licenseImgs, temp);
          this.licenseImgs = Array.from(new Set(this.licenseImgs));
        }
      }
    },
    directUpload(file, uploadType, thumb = false) {
      var self = this;
      let cosMap = {
        image: cosByUploadType(uploadTypeImage),
        video: cosByUploadType(uploadTypeVideo),
      };
      let cos = uploadType === uploadTypeImage ? cosMap.image : cosMap.video;
      let bucket =
        uploadType === uploadTypeImage ? bucketMap.image : bucketMap.video;
      return new Promise((resolve, reject) => {
        cos.putObject(
          {
            Bucket: bucket /* 必须 */,
            Region: "ap-shanghai" /* 存储桶所在地域，必须字段 */,
            Key: randomFilename() /* 必须 */,
            StorageClass: "STANDARD",
            Body: file, // 上传文件对象
            onProgress: function (progressData) {
              self.btnDisabled = true;
              if (thumb) {
                // 带封面的上传的组件
                self.$refs["upload"].uploadFiles.map((item) => {
                  if (item.raw.uid === file.uid) {
                    item.percentage = parseInt(progressData.percent * 100);
                  }
                });
              } else {
                self.$refs["upload1"].uploadFiles.map((item) => {
                  if (item.raw.uid === file.uid) {
                    item.percentage = parseInt(progressData.percent * 100);
                  }
                });
              }
            },
          },
          function (err, data) {
            if (err) {
              reject(err);
            } else {
              resolve(data);
            }
          }
        );
      });
    },
    async initSelect() {
      // 获取学历、职称选项
      // let res = await getSelect(requestdata);
      // this.industrylist = res.data.result;
      // let deleteList = ['计算机/互联网/通信/电子','会计/金融/银行/保险','贸易/消费/制造/营运','制药/医疗','房地产/建筑','专业服务/教育/培训','服务业','物流运输','政府/非营利组织/其他']
      // let newList = []  // 过滤部分选项
      // this.industrylist.forEach((item)=>{
      //   if(deleteList.indexOf(item.val) === -1){
      //     newList.push(item)
      //   }
      // })
      // this.industrylist = newList
      // 获取学历list
      let res1 = await getSelectFatherSonByType(4);
      this.educations = res1.data;
      // 获取职称list
      let res2 = await getSelectFatherSonByType(10);
      this.jobtitlelist = res2.data;
    },
    getPhoneCode() {
      const TIME_COUNT = 60;
      var reg =
        /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/;
      console.log(reg.test(this.form.contactWay));
      if (reg.test(this.form.contactWay) == false) {
        ElMessage.warning("您输入的手机号码格式不正确，请重新输入");
        return;
      }
      getPhoneCode({ phone: this.form.contactWay })
        .then(() => {
          if (!this.timer) {
            this.count = TIME_COUNT;
            this.showCode = false;
            this.timer = setInterval(() => {
              if (this.count > 0 && this.count <= TIME_COUNT) {
                this.count--;
              } else {
                this.showCode = true;
                clearInterval(this.timer);
                this.timer = null;
              }
            }, 1000);
          }
          ElMessage.success("发送成功");
        })
        .catch((err) => {
          ElMessage.error({ message: err.message });
        });
    },
    async submit() {
      const { memberName, contactWay, code , labName , belong , direction , education , jobTitle , email , introduction } = this.form;
      if(!labName){
        ElMessage.warning("实验室名称不能为空");
        return;
      }
      if(!belong){
        ElMessage.warning("所属单位不能为空");
        return;
      }
      if (!memberName) {
        ElMessage.warning("姓名不能为空");
        return;
      }
      if(!direction){
        ElMessage.warning("研究方向不能为空");
        return;
      }
      else if(direction.split(',').length > 5 || direction.split('，').length > 5){
        ElMessage.warning("研究方向最多输入5个!");
        return
      }
      else{
        let flag = false
        direction.replace(reg,',').split(',').find((item)=>{
          if(item.length > 10){
            flag = true
            return true
          }
        })
        if(flag){
          ElMessage.warning('每个研究方向最长10个字符!')
          return
        }
      }
      if (!education) {
        ElMessage.warning("学历不能为空");
        return;
      }
      if (!jobTitle) {
        ElMessage.warning("职称不能为空");
        return;
      }
      if (!email) {
        ElMessage.warning("邮箱不能为空");
        return;
      }
      else{
        const reg = /^([a-zA-Z]|[0-9])(\w)+@[a-zA-Z0-9]+\.([a-zA-Z]{2,4})$/;
        if (!reg.test(email)) {
          ElMessage.warning("请输入正确格式邮箱")
          return
        }
      }
      if(introduction?.length > 0 && introduction?.length < 10){
        ElMessage.warning("自我介绍不能少于10个字");
        return;
      }
      if(!this.location || this.location.length === 0){
        ElMessage.warning('请选择地址')
        return
      }
      if(this.licenseImgs.length == 0){
        ElMessage.warning('请上传认证信息')
        return
      }
      if (!contactWay && this.unLogin) {
        ElMessage.warning("手机号不能为空");
        return;
      }
      if (!code && this.unLogin) {
        ElMessage.warning("验证码不能为空");
        return;
      }
      const reg = new RegExp('，','g');    // 详情页面宽度限制
      const labForm = {
        "orgIntroduction": introduction,
        "name": labName,
        "belong": belong,
        "createMan": memberName,
        "label": direction.replace(reg,',').split(','),
        "educationId": education,
        "positionId": jobTitle,
        "email": email,
        "addressAreaId": parseInt(this.location[2]),
        "addressAreaNames": this.location,
        "addressAreaName": this.$refs["cascaderAddr"].getCheckedNodes()[0].pathLabels[2],
        "addressCityId": parseInt(this.location[1]),
        "addressCityName": this.$refs["cascaderAddr"].getCheckedNodes()[0].pathLabels[1],
        "addressProvinceId": parseInt(this.location[0]),
        "addressProvinceName": this.$refs["cascaderAddr"].getCheckedNodes()[0].pathLabels[0],
        "license": "",
        "phone": "1",
        "qualifications": [],
        "salesmanId": "",
        "scaleId": 100,
        "signTime": "",
        "summary": "",
        "unionId": '',
        "verifyStatus": 0
      }
      let imgs = JSON.stringify(this.licenseImgs)
      imgs = imgs.replace('[','')
      imgs = imgs.replace(']','')
      imgs = imgs.replace(/"/g,'')
      labForm.license  = imgs
      labForm.qualifications = this.licenseImgs
      if(this.unLogin){ // 未登录，先登录
        const params = {
          machineCode: "",
          version: requestVersion,
          canal: 1,
          type: 0,
          code: code,
          phone: contactWay,
        }
        this.$store.dispatch("user/login", params).then(() => { // 登录后，绑定身份
          let userInfo = localStorage.getItem('userInfos')
          labForm.phone = contactWay
          labForm.unionId = JSON.parse(userInfo).memberInfo.id
          labForm.salesmanId = JSON.parse(userInfo).memberInfo.id
          this.submitForm(labForm)
        })
      }
      else{
        let userInfo = localStorage.getItem('userInfos')
        labForm.phone = JSON.parse(userInfo).memberInfo.phone
        labForm.unionId = JSON.parse(userInfo).memberInfo.id
        labForm.salesmanId = JSON.parse(userInfo).memberInfo.id
        this.submitForm(labForm)
      }
    },
    async submitForm(labForm){ // 提交表单
      const [err,res] = await to(bindLab(labForm))
      if(err){
        console.log('提交表单错误:',err);
        if(this.unLogin) this.unLogin = false
        if(err.message === '已有所属机构') this.$router.push('/user-center')
        return
      }
      if(res.code === 'Sx200'){
        this.$store.dispatch("user/editOrganization", 0)
        ElMessage.success('提交成功!')
        trackCreate(this.$route,'数字科创中心')  // 创建埋点
        this.$router.push('/')
      }
    },
    inputChange(e) {
      // 只能输入数字
      let a = e.key.replace(/[^\d]/g, "");
      if (!a && e.keyCode !== 8) {
        e.preventDefault();
      }
    },
    imgLoad() {
      this.imgLoaded = true;
    },
    checkLogin(type) {
      if (getToken() === "") {
        ElMessage.warning("你尚未登录!");
        this.$router.push({
          path: "/login",
          query: { redirect: "/industrial-garden" },
        });
      } else {
        // uat: 185580801622017 线上: 180517873975297
        if (type === 1) {
          this.$store.commit("message/SET_OPEN_SERVICE_WINDOW");
          // let id = (environment === 'tprod' || environment === 'online' ) ? 180517873975297 : 185580801622017
          // this.$store.dispatch("message/startCommunication", {id: id,serverId:'',title:'',quickChat:false, unlimited: true})  // 在线联系不需要需求id、标题及承接需求quickChat
        } else {
          this.showCustom = true;
        }
      }
    },
    goToQuestion() {
      window.open("https://www.wjx.top/vj/tRNSOxy.aspx", "_blank");
    },
  },
};
</script>

<style scoped lang="scss">
.container {
  width: 100%;
  min-width: 1200px;
  margin-top: 0;
  background: #ffffff;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-bottom: 50px;
  .title {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .form {
    margin: -60px 360px 55px 360px;
    text-align: center;
    background: #fff;
    background: #ffffff;
    box-shadow: 0px 2px 72px 0px rgba(0, 0, 0, 0.07);
    border-radius: 8px;
    p {
      font-size: 44px;
      line-height: 44px;
      margin: 45px 0px;
    }
    .el-form {
      padding: 0px 20px;
    }
    .form-code {
      display: flex;
      .count-down{
        width: 110px;
        margin-left: 40px;
        height: 40px;
        border-radius: 40px;
        border: 1px solid #b0b5c0;
        line-height: 40px;
        text-align: center;
      }
    }
    button {
      background: linear-gradient(180deg, #275cdf 0%, #327af0 100%) !important;
      border: none;
      border-radius: 40px;
      margin-left: 40px;
      height: 40px !important;
    }
  }
  .img-padding {
    padding: 0px 360px;
  }
}
::v-deep .el-input__inner {
  background: #f2f2f2;
}
::v-deep .el-textarea__inner {
  background: #f2f2f2;
  height: 100%;
}
::v-deep .el-textarea {
  height: 100px;
}
.button {
  background: #eaf2fd;
  border-radius: 10px;
  color: #4a1bd9;
  padding: 24px 26px;
  font-size: 1.2vw;
  cursor: pointer;
}
.custom-dialog {
  border-radius: 15px;
  .custom-top {
    img {
      width: 1072px;
      margin-top: -100px;
    }
    display: flex;
    flex-direction: column;
    align-items: center;
    .title {
      font-size: 40px;
      font-family: Source Han Sans SC;
      font-weight: 500;
      color: #0e0e0e;
    }
    .container-dialog {
      width: 50%;
      margin: 20px;
      .infos {
        font-size: 28px;
        font-family: Source Han Sans SC;
        font-weight: 400;
        color: #4b2f08;
      }
      input {
        background: #fffefb;
        border: 2px solid #ffe8bd;
        border-radius: 36px;
        margin-top: 10px;
        width: 100%;
        height: 36px;
        font-size: 24px;
        text-align: center;
      }
    }
    .commit-button {
      width: 460px;
      height: 40px;
      background: #ebd692;
      border-radius: 36px;
      font-size: 30px;
      font-family: Source Han Sans SC;
      font-weight: 400;
      color: #563b15;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 20px;
      cursor: pointer;
    }
  }
}
.required {
  :deep() .el-form-item__label {
    padding-left: 10px !important;
    position: relative;
  }
  :deep() .el-form-item__label::before {
    content: "*";
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #e33e3f;
    line-height: 12px;
    position: absolute;
    left: 0;
    top: 50%;
    margin-top: -6px;
  }
}
.upload {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 0 !important;
  :deep() .el-form-item__content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  .small-tip {
    position: absolute;
    font-size: 12px !important;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #666666;
    line-height: 12px;
    top: -42px;
    left: 170px;
    white-space: nowrap;
    margin: 0 !important;
  }
  .tip {
    font-size: 12px !important;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #999999;
    margin: 0 !important;
  }
}
.button {
  width: 180px !important;
  height: 40px !important;
  background: linear-gradient(180deg, #275cdf 0%, #327af0 100%) !important;
  border-radius: 40px !important;
  font-size: 14px !important;
  color: #ffffff !important;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
.icon-delete {
  background-color: rgba(0,0,0,0.2);
  padding: 3px 10px 0 10px;
  top: 0;
  right: 0;
  position: absolute;
  cursor: pointer;
}
</style>