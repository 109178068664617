<template>
  <div>
    {{ detail.compName }}
  </div>
</template>


<script>
import { searchDetailById } from "@/api/search";
import { openVipTipWindow } from "@/utils/tool";
export default{
  data(){
    return{
      detail: {},
      id: '',
    }
  },
  watch:{
    $route(to){
      if(to.path !== '/industry-detail') return
      this.getQuery('1')
    }
  },
  mounted(){
    this.getQuery()
  },
  methods:{
    getQuery(type){
      this.id = this.$route.query.id + ''
      if(type === '1') return ''
      this.getDetail()
    },
    getDetail(){
      searchDetailById({
        "id": this.id,
        "type": 13
      }).catch(err=>{
        if(err.message === 'VIP专属功能'){
          openVipTipWindow()
          this.$router.push('/search-industry')
        }
      }).then(res=>{
        this.detail = res.data
        console.log(res,'hehe');
      })
    },
  }
}
</script>