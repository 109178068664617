<template>
  <div class="wrap">
    <img src="../../assets/images/activity/solution1.png" />
    <img src="../../assets/images/activity/solution2.png" />
    <img style="position:relative;top:-5px" src="../../assets/images/activity/solution3.png" />
    <img style="position:relative;top:-10px" src="../../assets/images/activity/solution4.png" />
    <img style="position:relative;top:-15px" src="../../assets/images/activity/solution5.png" />
    <img style="position:relative;top:-20px" src="../../assets/images/activity/solution6.png" />
    <img src="../../assets/images/activity/solution7.png" />
    <div class="btns-wrap">
      <div class="btns">
        <span @click="startChat">在线咨询</span>
        <span class="tel">电话联系: 4006265298</span>
      </div>
    </div>
  </div>
</template>

<script>
// import {environment} from "@/utils/consts";
export default {
  created() {
    this.$store.commit('message/SET_ACTIVENAV', '-')
    this.$store.commit('message/SET_BG', false)
  },
  methods: {
    startChat() {
      this.$store.commit("message/SET_OPEN_SERVICE_WINDOW")
      // 客服ID uat: 185580801622017 线上: 180517873975297
      // let id = (environment === 'tprod' || environment === 'online' ) ? 180517873975297 : 185580801622017
      // this.$store.dispatch("message/startCommunication", {id: id,serverId:'',title:'',quickChat:false, unlimited: true})  // 在线联系不需要需求id、标题及承接需求quickChat
    }
  }
}
</script>

<style lang="scss" scoped>
.wrap {
  img {
    width: 100%;
  }
  .btns-wrap {
    position: fixed;
    bottom: 8vh;
    left: 0;
    right: 0;
    width: 100%;
    z-index: 2000;
    .btns {
      display: flex;
      justify-content: center;
      span, a {
        display: inline-block;
        width: 320px;
        height: 80px;
        line-height: 80px;
        color: $--color-theme;
        border: 2px solid $--color-theme;
        text-align: center;
        border-radius: 55px;
        background: #fff;
        font-size: 45px;
        cursor: pointer;
      }
      .tel {
        color: #fff;
        border: none;
        background: $--color-theme;
        margin-left: 100px;
        font-size: 40px;
        width: 522px;
      }
    }     
  }
 
}

</style>






