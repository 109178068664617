<template>
<div>
  <div v-if="ismOrpc == 'pc'">
		<div class="top-bg"></div>
		<div class="contact-intro">
			<div class="contact-width">
				<div class="contact-content">
					<div style="text-align: left;width: 100%;margin-bottom: 20px"><p class="contact-title">科企岛公司介绍</p></div>
					<p class="contact-text">科企岛是结合产学研用为一体的互联网技术服务平台，高校科研院所的技术专家在科企岛上创建线上技术服务工作室，充分展现他们的科研与科研成果，随时随地在线解决企业的技术服务需求，并开放实验室设施设备与企业共享，全方位满足企业的技术服务需求。</p>
				</div>
				<div class="contact-content">
					<div class="contact-img"></div>
				</div>
			</div>
		</div>
		<div class="contact-mobile">
			<div class="contact-width">
				<div class="contact-content">
					<div class="phone-logo">
						<img src="../../assets/images/Android-logo.png" />
						<span>安卓手机</span>
					</div>
					<p class="phone-text">用户可以通过各大安卓应用市场（360手机助手、华为应用市场、小米应用市场、应用宝、百度手机助手、vivo应用商店、oppo应用商店、魅族应用商店）进行安装。</p>
					<div class="android-QR">
						<img class="img" src="@/assets/images/footer/android.png" />
					</div>
				</div>
				<div class="contact-content">
					<div class="phone-logo">
						<img style="width:37px;height: 46px;" src="../../assets/images/iphone-logo.png" />
						<span style="position: relative;top: 5px">苹果手机</span>
					</div>
					<p class="phone-text">苹果手机用户可以通过App Store搜索“科企岛”进行下载安装</p>
					<div class="android-QR">
						<img class="img" src="@/assets/images/footer/ios.png" />
					</div>
				</div>
			</div>

		</div>
  </div>
	<mobile-contact-us v-else></mobile-contact-us>
</div>
</template>

<script>
import { isMobile } from '@/config/mobile' 
import MobileContactUs from '@/views/mobile/contactUs'

export default {
  name: "contactus",
	data() {
		return {
			ismOrpc: 'pc'
		}
	},
	created(){
		if (this.isMobile()) {
			this.ismOrpc = 'mobile'
      return
		}
		this.$store.commit('message/SET_ACTIVENAV','联系我们')
	},
	components: {
		MobileContactUs
	},
	methods: {
		isMobile
	}
}
</script>

<style scoped lang="scss">
.top-bg {
	height: 350px;
	width: 100%;
  background: url(../../assets/images/contact-bg.png) center;
  background-size: cover;
}
.contact-intro {
	height: 400px;
	width: 100%;
  display: flex;
  justify-content: space-around;
}
.contact-width {
	width: 1200px;
	height: 400px;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
}
.contact-mobile {
	height: 400px;
	width: 100%;
  display: flex;
  justify-content: center;
	box-sizing: border-box;
	background: #EBEBEB;
}
.contact-content {
	width: 48%;
	height: 400px;
	padding: 50px 0;
	box-sizing: border-box;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
}
.contact-title {
	font-size: 34px;
	font-weight: bold;
	color: $--color-theme;
	margin: 0 0;
	position: relative;
}
.contact-text {
	font-size: 20px;
	color: #666666;
	line-height: 1.6;
}

.contact-img {
	width: 100%;
	height: 300px;
	background: url(../../assets/images/contact-bg2.png);
	background-position: center;
	background-size: cover;
}
.phone-logo {
	width: 50%;
	height: 50px;
	box-sizing: border-box;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	position: relative;
	top: -20px;
	&:after {
		content: '';
		position: absolute;
		bottom: -10px;
		width: 100%;
		height: 1px;
		background: #C2C2C2;
	}
	img {
		width: 50px;
		height: 53px;
		margin-right: 24px;
	}
	span {
		font-size: 24px;
		color: #666666;
	}
}
.phone-text {
	color: #666666;
	font-size: 16px;
	text-align: center;
	letter-spacing: 1px;
	height: 64px;
}
.android-QR {
	height: 120px;
	width: 120px;
	background: white;
	border-radius: 5px;
	.img {
		width: 100%;
		height: 100%;
	}
}

</style>
