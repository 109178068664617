<template>
  <div>
    <el-input @input="handlePhone" v-model="phone" class="e-input" maxlength="11" />
    <el-input @input="handleCaptcha" v-model="code" class="e-input e-code" maxlength="6" />
    <button class="b-code" :class="{'disable-code': !showCode}" @click="getPhoneCode()">验证码</button>
    <button class="b-code" :class="{'disable-code': !showCode}" @click="login">登录</button>
  </div>
</template>

<script>
import { getPhoneCode } from "@/api/user";
import { ElMessage } from "element-plus";
import { TPSLogin,getLocalInfo } from "@/utils/talent-project-system/login";

export default{
  data(){
    return{
      phone: '',
      code: '',
      showCode: true, // 是否显示倒计时
      timer: null,
      loading: false,
    }
  },
  mounted(){
    if(getLocalInfo()) this.$router.push('/talent-project-talents')
  },
  methods:{
    login(){
      if(!this.phone){
        ElMessage.warning('请输入手机号！')
        return
      } else if(!this.code){
        ElMessage.warning('请输入验证码！')
        return
      }
      this.loading = true
      TPSLogin({
        phone: this.phone,
        code: this.code,
      }).then(()=>{
        if(this.$route.query.redirect){
          window.location.href = this.$route.query.redirect
        }
        else{
          this.$router.push({
            path: '/talent-project-talents'
          })
        }
      }).finally(()=>{
        this.loading = false
      })
    },
    handlePhone(e){
      const pattern = new RegExp("^[0-9]*$")
      this.phone = Array.from(e).filter(v => pattern.test(v)).join("")
    },
    handleCaptcha(e) {  // 验证码处理，只输入数字
      const pattern = new RegExp("^[0-9]*$")
      this.code = Array.from(e).filter(v => pattern.test(v)).join("")
    },
    getPhoneCode() {
      const TIME_COUNT = 60;
      const reg =
        /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/;
      if (reg.test(this.phone) == false) {
        ElMessage.warning("您输入的手机号码格式不正确，请重新输入");
        return;
      }
      getPhoneCode({ phone: this.phone })
        .then(() => {
          if (!this.timer) {
            this.count = TIME_COUNT;
            this.showCode = false;
            this.timer = setInterval(() => {
              if (this.count > 0 && this.count <= TIME_COUNT) {
                this.count--;
              } else {
                this.showCode = true;
                clearInterval(this.timer);
                this.timer = null;
              }
            }, 1000);
          }
          ElMessage.success("发送成功");
        })
        .catch((err) => {
          ElMessage.error({ message: err.message });
        });
    },
  }
}
</script>

<style lang="scss" scoped>
  
</style>