<template>
  <div class="container">
    <p style="margin-top: 20px;">上传研究院文件</p><input accept=".json" type="file" @change="handleFileUpload" id="file1" multiple/>
    <div class="list-container">
      <div class="card" v-for="(item,index) in lists" :key="index">
        <img v-if="item.logo" :src="item.logo" style="width: 80px;height: 80px;"/>
        <div v-else style="width: 79px;height: 79px;border: 1px solid red;"></div>
        <p class="text-ol-ell">{{ item.name }}<br/>{{ item.school }}</p>
      </div>
    </div>
  </div>
</template>


<script>


export default{
  data(){
    return{
      lists: [],
      val: null,
    }
  },
  methods:{
    handleFileUpload(event){
      const files = event.target.files;
      // 对上传的文件数组进行处理
      files.forEach((file)=>{
        if (file) {
          const reader = new FileReader()
          reader.readAsText(file, 'utf8')
          reader.onload = () => {
            const val = JSON.parse(reader.result)
            val.institu.forEach((item)=>{
              item.school = val.school
              this.lists.push(item)
            })
          }
          reader.onerror = () => {
            console.log('onError')
          }
        }
      })
    },

  }
}
</script>

<style lang="scss" scoped>
.container{
  width: 1200px;
  margin: 0 auto;
  button{
    margin: 20px 0;
  }
  .list-container{
    margin-top: 50px;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    .card{
      width: 33%;
      margin-bottom: 20px;
      display: flex;
      align-items: center;
      p{
        font-size: 16px;
        width: calc(100% - 80px);
      }
    }
  }
}
</style>