<template>
  <div class="not-found-page" ref="notFoundPage">
    404 - 该页面不存在
  </div>
</template>

<script>
export default {
  name: "404",
  mounted() {
    this.$store.commit('message/SET_ACTIVENAV','-')
    this.$store.commit('message/SET_BG', false)
    setTimeout(() => {this.$router.go(-1)}, 3000)
  }
}
</script>

<style scoped>
.not-found-page {
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  font-weight: bold;
}
</style>
