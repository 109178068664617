<template>
  <div class="container">
    <div>
      <img src="https://ol-image-1304545655.cos.ap-shanghai.myqcloud.com/PC-2023/11/18/1702887181926969" class="img"/>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.container{
  width: 100%;
  .img{
    width: 100%;
  }
  .light{
    width: 100%;
    background: #ffffff;
    display: flex;
    justify-content: center;
  }
  .dark{
    width: 100%;
    background: #F6F7F9;
    display: flex;
    justify-content: center;
  }
}
</style>