<template>
  <div class="box">
    <div class="bg"></div>
    <div class="intro">
      <h4>科企岛公司介绍</h4>
      <p>科企岛是结合产学研用为一体的互联网技术服务平台，高校科研院所的技术专家在科企岛上创建线上技术服务工作室，充分展现他们的科研与科研成果，随时随地在线解决企业的技术服务需求，并开放实验室设施设备与企业共享，全方位满足企业的技术服务需求。</p>
    </div>
    <div class="down">
      <div class="phone-logo">
        <img src="../../assets/images/iphone-logo.png" />
        <span>苹果手机</span>
      </div>
      <p>苹果手机用户可以通过App Store搜索“科企岛”进行下载安装。</p>
			<img class="img" src="@/assets/images/footer/ios.png" />
    </div>
    <div class="down">
      <div class="phone-logo">
        <img src="../../assets/images/Android-logo.png" />
        <span>安卓手机</span>
      </div>
      <p>用户可以通过各大安卓应用市场（360手机助手、华为应用市场、小米应用市场、应用宝、百度手机助手、vivo应用商店、oppo应用商店、魅族应用商店）进行安装。</p>
			<img class="img" src="@/assets/images/footer/android.png" />
    </div>
  </div>
</template>

<style lang="scss" scoped>
  .box {
    margin-bottom: 0.4rem;
    .bg {
      width: 10rem;
      height: 4.41rem;
      background: url(../../assets/images/mobile/bg-contact.png) center;
      background-size: cover;
    }
    .intro {
      width: 9.55rem;
      height: 4.96rem;
      background: url(../../assets/images/mobile/bg-contect2.png) center;
      background-size: cover;
      text-align: center;
      padding: 0.53rem;
      box-sizing: border-box;
      margin: -0.8rem auto;
      h4 {
        color: #FF9800;
        font-size: 0.51rem;
        margin-bottom: 0.2rem;
      }
      p {
        color: #333;
        font-size: 0.37rem;
        text-align: left;
      }
    }
    .down {
      padding: 0 0.75rem;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      margin-top: 1.2rem;
      .phone-logo {
        display: flex;
        justify-content: center;
        align-items: center;
        img {
          width: 0.49rem;
          height: 0.61rem;
          margin-right: 0.32rem;
        }
        span {
          font-size: 0.35rem;
          font-family: PingFang SC;
          font-weight: bold;
          color: #666666;
        }
      }
      p {
        font-size: 0.35rem;
        font-family: Source Han Sans SC;
        font-weight: 400;
        color: #666666;
        margin: 0.28rem 0;
      }
      .img {
        width: 2.12rem;
        height: 2.12rem;
      }
    }
  }
</style>
